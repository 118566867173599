import React, { useEffect, useState } from 'react';

export type ArrowTipProps = {
  variant: 'primary' | 'secondary' | 'orange';
  height: number;
  direction: 'right' | 'left' | 'top' | 'bottom';
};

const ArrowTip = ({ variant, height, direction }: ArrowTipProps) => {
  const [rotationDeg, setRotationDeg] = useState<number>(0);

  useEffect(() => {
    switch (direction) {
      case 'right':
        setRotationDeg(0);
        break;
      case 'left':
        setRotationDeg(180);
        break;
      case 'top':
        setRotationDeg(-90);
        break;
      case 'bottom':
        setRotationDeg(90);
        break;
    }
  }, [direction]);

  return (
    <svg
      height={`${height}px`}
      xmlns="http://www.w3.org/2000/svg"
      fill="none"
      viewBox="0 0 13 23"
      transform={`rotate(${rotationDeg} 0 0)`}
    >
      <path
        stroke={
          variant == 'primary'
            ? '#F8FAFC'
            : variant == 'orange'
            ? '#1463f3'
            : '#0F0F0E'
        }
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth="2.7"
        d="m2 2 9 10-9 9"
      />
    </svg>
  );
};

export default ArrowTip;
